import { Dialog, Transition } from '@headlessui/react';
import { ArrowRight, Check, X } from 'phosphor-react';
import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BookingPartnerSelect } from './BookingPartnerSelect';
import { BookingPayment } from './BookingPayment';
import { BookingSteps } from './BookingSteps';

export function Wizard() {
  const params = useParams();
  const navigate = useNavigate();
  const step = parseInt(params.step || '1');
  const open = step > 1;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-20 inset-0 overflow-y-auto max-w-[414px] mx-auto"
        onClose={() => navigate('../')}
        initialFocus={undefined}
      >
        <div className="flex items-end justify-center min-h-screen p2t-4 p2x-4 p2b-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="h-[calc(100vh-3rem)] min-w-full relative inline-flex flex-col align-bottom bg-white round2ed-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="z-10 block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  onClick={() => navigate('../')}
                >
                  <span className="sr-only">Close</span>
                  <X className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <BookingSteps step={step} />
              <div className="flex-1 overflow-scroll">
                <div className="my-3 sm:mt-0 sm:ml-4 sm:text-left">
                  {step === 2 && <BookingPartnerSelect />}
                  {step === 3 && <BookingPayment />}
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => navigate(`../${step - 1}`)}
                  type="button"
                  className="mr-3 flex-1 inline-flex items-center justify-center px-4 py-3 border border-gray-300 shadow-sm text-base rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Zurück
                </button>
                {step <= 2 && (
                  <button
                    onClick={() => navigate(`../${step + 1}`)}
                    type="button"
                    className="flex-1 inline-flex items-center justify-center px-4 py-3 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    Weiter
                    <ArrowRight weight="bold" className="h-5 w-5 ml-1" />
                  </button>
                )}
                {step === 3 && (
                  <button
                    onClick={() => navigate('../success')}
                    type="button"
                    className="flex-1 inline-flex items-center justify-center px-4 py-3 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    Buchen
                    <Check weight="bold" className="h-5 w-5 ml-1" />
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
