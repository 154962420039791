/* This example requires Tailwind CSS v2.0+ */
import { RadioGroup } from '@headlessui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { classNames } from '../util';

export default function TestStart() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="max-w-7xl mx-auto relative z-10 py-4 px-2 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-extrabold font-medium text-gray-900">
          Testlauf / Ansicht wählen
        </h2>
      </div>

      <p className="mt-1 text-gray-700">
        Dies ist die Mobilansicht eines Buchungsablaufes für Tennis- / Sportplätze. Die vorhandenen
        Buchungsdaten sind nicht echt und wurden generiert.
      </p>
      <p className="mt-1 text-gray-700">Bitte wählen Sie den vom Testleiter vorgegebene Ansicht:</p>

      <RadioGroup
        value={params.testung}
        onChange={(testung: string) => navigate(`/booking/${testung}`)}
        className="mt-2 mb-4"
      >
        <RadioGroup.Label className="sr-only">Testung wählen</RadioGroup.Label>
        <div className="grid grid-cols-3 gap-3">
          {['A1', 'A2', 'A3'].map((t) => (
            <RadioGroup.Option
              key={t}
              value={t}
              className={({ active, checked }) =>
                classNames(
                  'cursor-pointer focus:outline-none',
                  active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                  checked
                    ? 'bg-sky-600 border-transparent text-white hover:bg-sky-700'
                    : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                  'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
                )
              }
            >
              <RadioGroup.Label as="p">{t}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
