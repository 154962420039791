import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BookingStart from './booking/BookingStart';
import { BookingSuccess } from './booking/BookingSuccess';
import { BookingView } from './booking/BookingView';
import { Header } from './booking/Header';
import TestStart from './booking/TestStart';

function App() {
  // const match = useMatch('/booking/:a/*');
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!match) navigate('/booking/A1');
  // }, [match, navigate]);

  return (
    <div className="max-w-[414px] mx-auto mt-12">
      <Header />
      <Routes>
        <Route path="booking/:testung" element={<BookingStart />} />
        <Route path="booking/:testung/:courtCount/success" element={<BookingSuccess />} />
        <Route path="booking/:testung/:courtCount/*" element={<BookingView />} />
        <Route path="*" element={<TestStart />} />
        {/* <Route path="*" element={<NotAvailable />} /> */}
      </Routes>
    </div>
  );
}

export default App;
