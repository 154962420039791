import { CheckCircle } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BookingSummary } from './BookingSummary';

export function BookingSuccess() {
  const navigate = useNavigate();
  return (
    <div className="bg-white p-4 min-h-[80vh]">
      <h2 className="text-2xl font-semibold text-gray-900">Buchung</h2>
      <div className="mt-1">
        <BookingSummary />
      </div>
      <div className="my-8 flex flex-col justify-center items-center text-lime-500 text-xl">
        <CheckCircle className="w-14 h-14 mb-2" />
        <span>Buchung erfolgreich</span>
      </div>
      <div className="flex">
        <button
          onClick={() => navigate('/booking')}
          type="button"
          className="flex-1 inline-flex items-center justify-center px-4 py-3 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        >
          OK
        </button>
      </div>
    </div>
  );
}
