import { differenceInMinutes, eachMinuteOfInterval, isSameMinute, set } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { bookingContext } from './BookingContext';
import { BookingSelect } from './BookingSelect';
import { BookingSlot } from './BookingSlot';
import { BookingSlotDuration } from './BookingSlotDuration';
import { BookingSlotDurationHeader } from './BookingSlotDurationHeader';
import { BookingSlotHeader } from './BookingSlotHeader';
import { BookingSlotSimple } from './BookingSlotSimple';
import { findNextBooking, getBooking, getPrice, isEcoFriendly, isSelectionAvailable } from './fake';
import { DateInterval, datesArrayToIntervals, unionFillInterval } from './Interval';

export function BookingDaySelect({ date }: { date: Date }) {
  const { selectedInterval, setSelectedInterval, selectedCourt, setSelectedCourt } =
    useContext(bookingContext);
  const [visibleDurations, setVisibleDurations] = useState<number[]>([60, 90]);
  const params = useParams();
  const courtCount = parseInt(params.courtCount || '5');

  useEffect(() => {
    setSelectedInterval(undefined);
    setSelectedCourt(undefined);
  }, [date, setSelectedCourt, setSelectedInterval]);

  const slots = useMemo(() => {
    const times = eachMinuteOfInterval(
      {
        start: set(date, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
        end: set(date, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
      },
      { step: 30 },
    );
    return datesArrayToIntervals(times);
  }, [date]);

  const onSelectChange = useCallback(
    (selected: boolean, interval: DateInterval) => {
      setSelectedInterval((cur) => {
        if (!cur) {
          return selected ? interval : cur;
        } else if (selected) {
          // add selection => union
          return unionFillInterval(cur, interval);
        } else {
          const cutStart = differenceInMinutes(interval.end, cur.start);
          const cutEnd = differenceInMinutes(cur.end, interval.start);
          const newSelection =
            cutStart < cutEnd
              ? { start: interval.end, end: cur.end } // cut from start
              : { start: cur.start, end: interval.start }; // cut from end
          // deselect all when just a single selected interval is left
          return isSameMinute(newSelection.start, newSelection.end) ? undefined : newSelection;
        }
      });
    },
    [setSelectedInterval],
  );

  const onClearSelection = useCallback(() => setSelectedInterval(undefined), []);

  const bookingOptions = useMemo(() => {
    return Array.from(Array(courtCount)).map((_, i) => {
      const price = selectedInterval && getPrice(selectedInterval, i);
      return {
        courtId: i,
        available: isSelectionAvailable(selectedInterval, i),
        price: price?.price || 0,
        ecoDiscount: price?.ecoDiscount || 0,
        ecoFriendly: !!selectedInterval && isEcoFriendly(selectedInterval, i),
      };
    });
  }, [courtCount, selectedInterval]);

  useEffect(() => {
    setSelectedCourt((cur) => {
      if (cur && bookingOptions[cur]?.available && bookingOptions[cur]?.ecoFriendly) return cur;

      const optionsSorted = [...bookingOptions].sort(
        (a, b) => (a.price || 0) - (a.ecoDiscount || 0) - ((b.price || 0) - (b.ecoDiscount || 0)),
      );

      const firstEcoFriendly = optionsSorted.find(
        ({ available, ecoFriendly }) => available && ecoFriendly,
      );
      if (firstEcoFriendly) return firstEcoFriendly.courtId;

      if (cur && bookingOptions[cur]?.available) return cur;

      const firstAvailable = optionsSorted.find(({ available }) => available);
      return firstAvailable?.courtId;
    });
  }, [bookingOptions, setSelectedCourt]);

  const bookingInfosBySlot = useMemo(() => {
    return slots.map((interval) => {
      return Array.from(Array(courtCount)).map((_, i) => {
        const booking = getBooking(interval, i);
        const price = getPrice(interval, i);
        return {
          courtId: i,
          booking,
          nextBooking: findNextBooking(interval, i),
          selectable:
            !booking &&
            (!selectedInterval ||
              isSelectionAvailable(unionFillInterval(selectedInterval, interval), i)),
          price: price.price,
          ecoDiscount: price.ecoDiscount,
          ecoFriendly: isEcoFriendly(interval, i),
        };
      });
    });
  }, [courtCount, selectedInterval, slots]);

  if (params.testung === 'A3')
    return (
      <div className="pb-40">
        <BookingSlotDurationHeader
          visibleDurations={visibleDurations}
          onVisibleDurationChange={setVisibleDurations}
        />
        {slots.map((interval, i) => (
          <BookingSlotDuration
            key={interval.start.getTime()}
            interval={interval}
            selectedCourt={selectedCourt}
            selectedInterval={selectedInterval}
            bookingInfos={bookingInfosBySlot[i]}
            visibleDurations={visibleDurations}
            onSelectChange={onSelectChange}
            onCourtSelectionChange={setSelectedCourt}
          />
        ))}
        {selectedInterval && (
          <BookingSelect
            bookingOptions={bookingOptions}
            selectedCourt={selectedCourt}
            selectedInterval={selectedInterval}
            onClearSelection={onClearSelection}
            onCourtSelectionChange={setSelectedCourt}
          />
        )}
      </div>
    );

  if (params.testung === 'A2')
    return (
      <div className="pb-40">
        {slots.map((interval, i) => (
          <BookingSlotSimple
            key={interval.start.getTime()}
            interval={interval}
            selectedCourt={selectedCourt}
            selectedInterval={selectedInterval}
            bookingInfos={bookingInfosBySlot[i]}
            onSelectChange={onSelectChange}
            onCourtSelectionChange={setSelectedCourt}
          />
        ))}
        <BookingSelect
          bookingOptions={bookingOptions}
          selectedCourt={selectedCourt}
          selectedInterval={selectedInterval}
          onClearSelection={onClearSelection}
          onCourtSelectionChange={setSelectedCourt}
        />
      </div>
    );

  return (
    <div className="pb-40">
      <BookingSlotHeader
        selectedCourt={selectedCourt}
        bookingOptions={bookingOptions}
        onCourtSelectionChange={setSelectedCourt}
      />
      {slots.map((interval, i) => (
        <BookingSlot
          key={interval.start.getTime()}
          interval={interval}
          selectedCourt={selectedCourt}
          selectedInterval={selectedInterval}
          bookingInfos={bookingInfosBySlot[i]}
          onSelectChange={onSelectChange}
          onCourtSelectionChange={setSelectedCourt}
        />
      ))}
      <BookingSelect
        bookingOptions={bookingOptions}
        selectedCourt={selectedCourt}
        selectedInterval={selectedInterval}
        onClearSelection={onClearSelection}
        onCourtSelectionChange={setSelectedCourt}
      />
    </div>
  );
}
