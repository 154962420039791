import { max, min } from 'date-fns';

export interface DateInterval {
  start: Date;
  end: Date;
}

export function unionFillInterval(a: DateInterval, b: DateInterval) {
  return { start: min([a.start, b.start]), end: max([a.end, b.end]) };
}

export function datesArrayToIntervals(dates: Date[]) {
  const intervals = dates.map((start, index, dates) => ({ start, end: dates[index + 1] }));
  intervals.pop(); // remove last element since end will be undefined
  return intervals;
}
