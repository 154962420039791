import { addDays, formatISO, subDays } from 'date-fns';
import { CalendarBlank, CaretLeft, CaretRight } from 'phosphor-react';
import { useCallback } from 'react';
import { FormattedDate } from 'react-intl';

export function DateSelect({
  date,
  onDateChange,
}: {
  date: Date;
  onDateChange: (date: Date) => void;
}) {
  const onChange = useCallback((e) => onDateChange(e.target.valueAsDate), [onDateChange]);
  const nextDay = useCallback(() => onDateChange(addDays(date, 1)), [date, onDateChange]);
  const prevDay = useCallback(() => onDateChange(subDays(date, 1)), [date, onDateChange]);

  return (
    <div className="flex justify-between items-center py-1">
      <button
        onClick={prevDay}
        className="p-2 rounded flex text-gray-500 hover:text-gray-400 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-gray-500"
      >
        <span className="sr-only">Previous Date</span>
        <CaretLeft weight="fill" className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex-1 relative rounded-md">
        <input
          type="date"
          onChange={onChange}
          value={formatISO(date, { representation: 'date' })}
          className="opacity-0 focus:opacity-100 hover:cursor-pointer focus:z-10 peer focus:ring-0 focus:outline-none focus:border-sky-500 w-full border-2 bg-white rounded-md"
        />
        <div className="peer-focus:hidden absolute inset-0 px-3 flex items-center pointer-events-none text-lg font-semibold">
          <div className="flex-1">
            <span className="xs:hidden">
              <FormattedDate value={date} weekday="short" />
            </span>
            <span className="hidden xs:inline">
              <FormattedDate value={date} weekday="long" />
            </span>
            &nbsp;
            <FormattedDate value={date} dateStyle="medium" />
          </div>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CalendarBlank className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>

      <button
        onClick={nextDay}
        className="p-2 rounded flex text-gray-500 hover:text-gray-400 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-gray-500"
      >
        <span className="sr-only">Next Date</span>
        <CaretRight weight="fill" className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
}
