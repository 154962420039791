import { CaretLeft, CaretRight } from 'phosphor-react';
import { useCallback } from 'react';
import { classNames } from '../util';

export function BookingSlotDurationHeader({
  visibleDurations,
  onVisibleDurationChange,
}: {
  visibleDurations: number[];
  onVisibleDurationChange: (visibleDurations: number[]) => void;
}) {
  const canAdd30 = visibleDurations[visibleDurations.length - 1] + 30 <= 480;
  const canSub30 = visibleDurations[0] - 30 > 0;

  const add30 = useCallback(
    () => canAdd30 && onVisibleDurationChange(visibleDurations.map((v) => v + 30)),
    [canAdd30, onVisibleDurationChange, visibleDurations],
  );
  const sub30 = useCallback(
    () => canSub30 && onVisibleDurationChange(visibleDurations.map((v) => v - 30)),
    [canSub30, onVisibleDurationChange, visibleDurations],
  );

  return (
    <div className="flex py-0.5 h-10 w-full sticky top-[6.25rem] bg-slate-100 z-20">
      <div className="w-20 flex-shrink-0 flex items-center justify-start px-1"></div>

      <div className="flex-1 ml-1 min-w-0 max-w-full flex relative">
        {visibleDurations.map((durationMin) => (
          <div
            key={durationMin}
            className="flex-1 min-w-0 flex flex-col items-center justify-center"
          >
            <span className="leading-none font-semibold">{durationMin}</span>
            <span className="leading-none font-semibold">min</span>
          </div>
        ))}

        <button
          onClick={sub30}
          disabled={!canSub30}
          className={classNames(
            'absolute left-0 top-0 px-2 rounded flex flex-col items-center text-gray-500 hover:text-gray-400 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-gray-500',
            !canSub30 && 'opacity-0',
          )}
        >
          {/* <span className="sr-only">Previous Date</span> */}
          <CaretLeft weight="bold" className="h-6 w-6" aria-hidden="true" />
          <span className="text-[10px]">-30</span>
        </button>
        <button
          onClick={add30}
          disabled={!canAdd30}
          className={classNames(
            'absolute right-0 top-0 px-2 rounded flex flex-col items-center text-gray-500 hover:text-gray-400 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-gray-500',
            !canAdd30 && 'opacity-0',
          )}
        >
          {/* <span className="sr-only">Previous Date</span> */}
          <CaretRight weight="bold" className="h-6 w-6" aria-hidden="true" />
          <span className="text-[10px]">+30</span>
        </button>
      </div>
    </div>
  );
}
