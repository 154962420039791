import { classNames } from '../util';
import { BookingOption } from './BookingSelect';

/**
 * function to make dynamic spacing pretty for 1 to 12 courts
 * returns percentage value for margin left/right
 */
function scaleStripMarginX(courtCount: number) {
  // width: `calc(100% / ${len} - ${80 / (len * len + 40)}rem)`,
  // width: `calc(100% / ${len} - ${450 / (len * len + 40)}%)`,
  // width: `min(${100 / len - 450 / (len * len + 40)}%, 1rem)`,
  const x = courtCount;
  return 225 / (x * x + 40);
}

export function BookingSlotHeader({
  bookingOptions,
  selectedCourt,
  onCourtSelectionChange,
}: {
  bookingOptions: BookingOption[];
  selectedCourt?: number;
  onCourtSelectionChange: (courtId: number) => void;
}) {
  const details = bookingOptions.length <= 3;

  return (
    <div className="flex py-0.5 h-10 w-full sticky top-24 bg-slate-100 z-10">
      <div className="w-20 flex-shrink-0 flex items-center justify-end px-4">
        <label>Platz</label>
      </div>

      <div className="flex-1 ml-1 min-w-0 max-w-full flex relative">
        {bookingOptions.map(({ courtId, available }, i) => (
          <div
            onClick={() => available && onCourtSelectionChange(courtId)}
            key={courtId}
            style={{
              margin: `0 ${scaleStripMarginX(bookingOptions.length)}%`,
            }}
            className="flex-1 min-w-0 flex items-center justify-center"
          >
            <div
              style={{
                // function to make spacing pretty for 1 to 12 courts
                // width: `calc(100% / ${len} - ${80 / (len * len + 40)}rem)`,
                // width: `calc(100% / ${len} - ${450 / (len * len + 40)}%)`,
                // width: `min(${100 / len - 450 / (len * len + 40)}%, 1rem)`,
                width: `min(100%, 1rem)`,
              }}
              className={`flex-shrink-0 relative`}
            >
              <span className="absolute inset-0 flex flex-col justify-center items-center font-semibold -tracking-widest -translate-x-px">
                <span
                  className={classNames(
                    'transition-all',
                    selectedCourt === courtId && 'text-sky-500',
                    !!selectedCourt && !available && 'opacity-50',
                    bookingOptions.length > 10 && 'text-[11px] xs:text-sm', // TODO
                  )}
                >
                  {courtId + 1}
                </span>
                <span className="absolute -bottom-[20px] ml-px w-px h-2 bg-gray-400"></span>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div
        className={classNames(
          // details ? 'w-40' : 'w-[3.25rem] sm:w-40', TODO
          details ? 'w-40' : 'w-[3.25rem]',
          'ml-4 mr-1',
        )}
      ></div>
    </div>
  );
}
