import { addMinutes, isBefore } from 'date-fns';
import { DateInterval } from './Interval';

export function genFakeBookingTimes(
  { start, end }: DateInterval,
  seed = 1,
  stepMin = 30,
): DateInterval[] {
  // https://stackoverflow.com/a/19303725/6292230 PRNG, not uniform but simple
  function random() {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  // 0: no slot (free) booking probability, 1: single slot booking probability, 2: ...
  // sum = 1
  const probability = [0.9, 0.025, 0.05, 0.025];

  const threshold = probability.reduce((t, p, i) => {
    t.push(p + (t[i - 1] || 0));
    return t;
  }, [] as number[]);

  const times: DateInterval[] = [];
  let cur = start;
  while (isBefore(cur, end)) {
    const rand = random();
    const span = threshold.findIndex((t) => rand < t);
    if (span < 1) {
      cur = addMinutes(cur, stepMin);
    } else {
      const end = addMinutes(cur, span * stepMin);
      times.push({ start: cur, end });
      cur = end;
    }
  }
  return times;
}
