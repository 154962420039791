import React, { useState } from 'react';
import { DateInterval } from './Interval';

export const bookingContext = React.createContext<{
  selectedInterval?: DateInterval;
  setSelectedInterval: React.Dispatch<React.SetStateAction<DateInterval | undefined>>;
  selectedCourt?: number;
  setSelectedCourt: React.Dispatch<React.SetStateAction<number | undefined>>;
}>({
  setSelectedInterval: () => {},
  setSelectedCourt: () => {},
});

export function BookingContextProvider({ children }: { children: React.ReactNode }) {
  const [selectedInterval, setSelectedInterval] = useState<DateInterval>();
  const [selectedCourt, setSelectedCourt] = useState<number>();

  return (
    <bookingContext.Provider
      value={{ selectedInterval, setSelectedInterval, selectedCourt, setSelectedCourt }}
    >
      {children}
    </bookingContext.Provider>
  );
}
