/* This example requires Tailwind CSS v2.0+ */
import { CaretRight, CloudSun, House } from 'phosphor-react';
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bookingContext } from './BookingContext';

export const sites = [
  {
    siteId: 1,
    sport: 'Tennis',
    site: 'Halle',
    address: 'An der Straße 1, 12345 Stadt',
    tags: 'Granulat',
    courtCount: 5,
    icon: House,
  },
  {
    siteId: 2,
    sport: 'Tennis',
    site: 'Traglufthallen',
    address: 'Andere Str. 2, 12345 Stadt',
    tags: 'Sand',
    courtCount: 2,
    icon: House,
  },
  {
    siteId: 3,
    sport: 'Tennis',
    site: 'Außen',
    address: 'An der Straße 3, 12345 Stadt',
    tags: 'Sand',
    courtCount: 6,
    icon: CloudSun,
  },
] as const;

export default function BookingStart() {
  const { setSelectedInterval, setSelectedCourt } = useContext(bookingContext);
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="max-w-7xl mx-auto relative z-10 py-4 px-2 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-extrabold font-medium text-gray-900">
          Tennisverein Testhausen
        </h1>
      </div>

      <p className="mt-1 text-gray-700">
        Willkommen im Tennisverein Testhausen.
        <br />
        In der Wintersaison stehen insgesamt 5&nbsp;Hallenplätze und zwei Traglufthallen zur
        Verfügung.
      </p>

      <h2 className="mt-4 text-2xl font-extrabold tracking-tight text-slate-800">
        Sportanlage wählen
      </h2>
      <div className="mt-6 grid grid-cols-1 gap-y-6 lg:XXgrid-cols-3 lg:XXgap-y-0 lg:gap-x-8">
        {sites.map(({ address, courtCount, site, siteId, sport, tags, icon: Icon }) => (
          <div
            key={siteId}
            onClick={() => {
              setSelectedCourt(undefined);
              setSelectedInterval(undefined);
              navigate(`${courtCount}`);
            }}
            className="flex bg-white rounded-2xl shadow-xl p-3"
          >
            <div>
              <Icon
                weight="fill"
                className="h-8 w-8 p-1.5 rounded-lg text-white bg-sky-500"
                aria-hidden="true"
              />
            </div>

            <div className="flex-1 relative pl-2 pr-1 pt-0.5">
              <h3 className="text-xl font-medium text-gray-900">
                {sport} - {site}
              </h3>
              <p className="mt-1 text-sm text-gray-500">{address}</p>
              <p className="mt-1.5 text-sm text-gray-500">
                {courtCount} Plätze
                <span className="ml-1.5 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                  {tags}
                </span>
              </p>
            </div>

            <div className="flex flex-col justify-center">
              <Link to={`${courtCount}`}>
                <div className="sr-only">Weiter zur Buchung</div>
                <CaretRight className="h-6 w-6 text-sky-500" aria-hidden="true" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
