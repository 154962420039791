import React, { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { BookingDaySelect } from './BookingDaySelect';
import { DateSelect } from './DateSelect';
import { initFakeTimes } from './fake';
import { Wizard } from './Wizard';

export function BookingView() {
  const [date, setDate] = useState<Date>(new Date());

  const proxySetDate = useCallback((date: Date) => {
    initFakeTimes(date);
    setDate(date);
  }, []);

  return (
    <div>
      <div className="p-2">
        <div className="sticky top-12 z-20 bg-slate-100">
          <DateSelect date={date} onDateChange={proxySetDate} />
        </div>
        <div className="my-2 flex items-start bg-teal-300/20 text-green-600 px-4 py-2 rounded-lg">
          <FaLeafSolidIcon className="w-5 h-5 mr-3 mt-0.5" />
          <span>
            Rabatt auf Nachhaltigkeit.
            <br />
            Diese Buchungen sparen Energie.
          </span>
        </div>
        <BookingDaySelect date={date} />
        <Routes>
          <Route path=":step" element={<Wizard />} />
        </Routes>
      </div>
    </div>
  );
}
