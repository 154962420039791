import { Minus, Plus, User } from 'phosphor-react';
import React, { useState } from 'react';

const lastPlayers = [
  { name: 'Max Mustermann', group: 'Mitglied' },
  { name: 'Erika Musterfrau', group: 'Gast' },
] as const;

export function BookingPartnerSelect() {
  const [members, setMembers] = useState(0);
  const [guests, setGuests] = useState(0);

  return (
    <div className="bg-white">
      <h2 className="text-2xl font-semibold text-gray-900">Mitspielende einladen</h2>
      <div className="flex items-center">
        <h3 className="text-lg font-semibold text-gray-900">Mitglieder</h3>
        <div className="flex-1 mx-3 border-b-2 border-dotted" />
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => setMembers((cur) => (cur === 0 ? 0 : cur - 1))}
            className="py-3 px-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <Minus weight="bold" className="h-5 w-5" aria-hidden="true" />
          </button>
          <span className="w-8 text-lg text-center font-semibold">{members}</span>
          <button
            type="button"
            onClick={() => setMembers((cur) => (cur === 4 ? 4 : cur + 1))}
            className="py-3 px-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <Plus weight="bold" className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {Array.from(Array(members)).map((_, i) => (
        <div key={i} className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="focus:ring-sky-500 focus:border-sky-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="max.mustermann@example.com"
          />
        </div>
      ))}
      <div className="flex items-center mt-1">
        <h3 className="text-lg font-semibold text-gray-900">Gäste</h3>
        <div className="flex-1 mx-3 border-b-2 border-dotted" />
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => setGuests((cur) => (cur === 0 ? 0 : cur - 1))}
            className="py-3 px-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <Minus weight="bold" className="h-5 w-5" aria-hidden="true" />
          </button>
          <span className="w-8 text-lg text-center font-semibold">{guests}</span>
          <button
            type="button"
            onClick={() => setGuests((cur) => (cur === 4 ? 4 : cur + 1))}
            className="py-3 px-3 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <Plus weight="bold" className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      {Array.from(Array(guests)).map((_, i) => (
        <div key={i} className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="focus:ring-sky-500 focus:border-sky-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="max.mustermann@example.com"
          />
        </div>
      ))}
      <h2 className="mt-2 text-2xl font-semibold text-gray-900">Letzte Mitspielende</h2>
      <div>
        {lastPlayers.map(({ name, group }) => (
          <div className="flex my-2 px-3 items-center text-gray-500 bg-gray-200 rounded-md">
            <User className="h-5 w-5 mr-2" />
            <div className="flex-1 flex flex-col">
              <span className="text-base leading-none">{name}</span>
              <span className="text-sm leading-none mt-0.5">{group}</span>
            </div>

            <button
              type="button"
              onClick={() =>
                alert('Vorschläge aus letzte Mitspieler übernehmen in Demo nicht verfügbar.')
              }
              className="py-3 px-3 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            >
              <Plus weight="bold" className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
