import {
  areIntervalsOverlapping,
  eachMinuteOfInterval,
  isAfter,
  isSameMinute,
  set,
} from 'date-fns';
import { genFakeBookingTimes } from './booking.test';
import { DateInterval, datesArrayToIntervals } from './Interval';

let fakeTimes: DateInterval[][];
initFakeTimes(new Date());

export function initFakeTimes(date: Date) {
  const seed = date.getMonth() * 30 + date.getDate();
  fakeTimes = Array.from(Array(12)).map((_, i) =>
    genFakeBookingTimes(
      {
        start: set(date, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
        end: set(date, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
      },
      i * seed,
    ),
  );
}

export function getBooking(interval: DateInterval, i = 0) {
  return fakeTimes[i].find((cInterval) => areIntervalsOverlapping(interval, cInterval));
}

export function findNextBooking(interval: DateInterval, i = 0) {
  return fakeTimes[i].find(
    (cInterval) =>
      isAfter(cInterval.start, interval.end) || isSameMinute(cInterval.start, interval.end),
  );
}

export function isEcoFriendly(interval: DateInterval, i = 0) {
  return (
    !getBooking(interval, i) &&
    fakeTimes[i].some(
      ({ start, end }) => isSameMinute(interval.end, start) || isSameMinute(interval.start, end),
    )
  );
}

export function getPrice(interval: DateInterval, i = 0) {
  const times = eachMinuteOfInterval(interval, { step: 30 });
  const slots = datesArrayToIntervals(times);
  const ecoCount = slots.reduce(
    (count, interval) => (isEcoFriendly(interval, i) ? count + 1 : count),
    0,
  );

  const baseEcoDiscount = 200;
  const basePrice = 1000;

  return {
    price: basePrice * slots.length,
    ecoDiscount: baseEcoDiscount * ecoCount,
  } as const;
}

export function isSelectionAvailable(interval?: DateInterval, i = 0) {
  return !!interval && !getBooking(interval, i);
}
