/* eslint-disable react/style-prop-object */
import { areIntervalsOverlapping, differenceInMinutes, formatISO, set } from 'date-fns';
import { Check, ShoppingCart } from 'phosphor-react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { classNames } from '../util';
import { BookingInfo } from './BookingSlot';
import { DateInterval } from './Interval';

export function BookingSlotSimple({
  bookingInfos,
  interval,
  selectedCourt,
  selectedInterval,
  onSelectChange,
  onCourtSelectionChange,
}: {
  bookingInfos: BookingInfo[];
  interval: DateInterval;
  selectedCourt?: number;
  selectedInterval?: DateInterval;
  onSelectChange: (selected: boolean, interval: DateInterval) => void;
  onCourtSelectionChange: (courtId: number) => void;
}) {
  const selected = !!selectedInterval && areIntervalsOverlapping(interval, selectedInterval);
  const { start, end } = interval;

  const minPrice = bookingInfos.reduce(
    (minPrice, { price, ecoDiscount }) => Math.min(minPrice, (price || 0) - (ecoDiscount || 0)),
    Number.MAX_SAFE_INTEGER,
  );

  const maxPrice = bookingInfos.reduce(
    (maxPrice, { price, ecoDiscount }) => Math.max(maxPrice, (price || 0) - (ecoDiscount || 0)),
    0,
  );

  const selectedPrice =
    typeof selectedCourt === 'number' &&
    (bookingInfos[selectedCourt].price || 0) - (bookingInfos[selectedCourt].ecoDiscount || 0);

  const selectedEcoFriendly =
    typeof selectedCourt === 'number' && bookingInfos[selectedCourt].ecoFriendly;

  const differentPrice = !!selectedPrice && selectedPrice !== minPrice;

  const maxBookingTime = bookingInfos.reduce(
    (maxBookingTime, { nextBooking }) =>
      Math.max(
        maxBookingTime,
        differenceInMinutes(
          nextBooking
            ? nextBooking.start
            : set(start, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
          start,
        ),
      ),
    0,
  );

  const isEcoFriendly = bookingInfos.some(({ ecoFriendly }) => ecoFriendly);

  // changes dynamically based on selection (maybe unwanted ?)
  // const availableCount = bookingInfos.reduce(
  //   (count, { selectable }) => (selectable ? count + 1 : count),
  //   0,
  // );
  const availableCount = bookingInfos.reduce(
    (count, { booking }) => (booking ? count - 1 : count),
    bookingInfos.length,
  );

  const notSelectable = bookingInfos.every(({ selectable }) => !selectable);

  return (
    <div key={start.toISOString()} className="flex py-[0.1875rem] h-14 w-full select-none">
      <label
        className={`w-full h-full flex items-center justify-between bg-white rounded-lg px-4 py-1.5 border shadow-sm ${
          selected
            ? 'ring-1 ring-sky-500 border-sky-500 text-sky-500' // focus-within:border-sky-400
            : 'border-white text-gray-700' // focus-within:border-gray-300
        } ${notSelectable && 'opacity-50'}`}
      >
        <span className="flex h-full flex-col items-start">
          <time
            dateTime={formatISO(start)}
            className="flex-1 flex items-center text-lg leading-none font-semibold"
          >
            <FormattedTime value={start} />
          </time>
          <span
            className={`flex-1 flex items-center text-sm leading-none ${
              !selected && 'text-gray-400'
            }`}
          >
            <FormattedMessage defaultMessage="bis" id="om06Wbe5" />
            &nbsp;
            <time dateTime={formatISO(end)}>
              <FormattedTime value={end} />
            </time>
          </span>
        </span>
        {/* TODO: fix jumping if counts differ */}
        <span className="flex-1 flex h-full flex-col items-start ml-[4%]">
          <span className="flex-1 flex items-center text-sm leading-none font-semibold">
            <span
              className={classNames(
                'h-4 w-4 mr-1 rounded',
                notSelectable
                  ? 'bg-tomato-500'
                  : maxBookingTime <= 30
                  ? 'bg-amber-400'
                  : 'bg-pear-500',
              )}
            />
            <FormattedMessage
              defaultMessage="{availableCount} / {totalCount} frei"
              id="jFh3LeRq"
              values={{ availableCount, totalCount: bookingInfos.length }}
            />
          </span>
          {maxBookingTime <= 60 && (
            <span
              className={`flex-1 flex items-center text-sm leading-none font-semibold ${
                !selected && 'text-gray-400'
              }`}
            >
              <span className="text-xs font-light mr-1 tracking-tight mt-px">max:</span>
              {maxBookingTime} min
            </span>
          )}
        </span>
        <span className="flex-1 flex h-full flex-col items-end mr-[4%]">
          <span
            className={classNames(
              'flex-1 flex items-center text-sm leading-none',
              !selected && (isEcoFriendly ? 'text-green-600' : 'text-gray-400'),
              differentPrice && 'line-through opacity-50',
            )}
          >
            {isEcoFriendly && <FaLeafSolidIcon className="w-3.5 h-3.5 mr-1 text-green-600" />}
            {minPrice !== maxPrice && <span className="mr-1">ab</span>}
            <FormattedNumber value={minPrice / 100} style="currency" currency="EUR" />
          </span>
          {differentPrice && (
            <span
              className={classNames(
                'flex-1 flex items-center text-sm leading-none',
                !selected && (selectedEcoFriendly ? 'text-green-600' : 'text-gray-400'),
              )}
            >
              {selectedEcoFriendly && (
                <FaLeafSolidIcon className="w-3.5 h-3.5 mr-1 text-green-600" />
              )}
              <FormattedNumber value={selectedPrice / 100} style="currency" currency="EUR" />
            </span>
          )}
        </span>
        <span className="relative w-6 h-6">
          <input
            checked={selected}
            disabled={notSelectable}
            onChange={() => onSelectChange(!selected, interval)}
            type="checkbox"
            className="absolute inset-0 h-6 w-6 focus:ring peer focus:outline-none focus:ring-sky-500 border-none rounded-md !bg-none !bg-transparent"
          />
          <Check weight="bold" className="h-6 w-6 hidden peer-checked:block" />
          <ShoppingCart
            weight="fill"
            className={classNames(
              'h-6 w-6 peer-checked:hidden',
              notSelectable ? 'text-gray-300' : 'text-sky-500',
            )}
          />
        </span>
      </label>
    </div>
  );
}
