import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../util';

export function BookingSteps({ step }: { step: number }) {
  const steps = useMemo(() => {
    return [{ name: 'Platz wählen' }, { name: 'Mitspielende' }, { name: 'Bezahlen' }];
  }, []);

  return (
    <nav aria-label="Progress">
      <ol className="flex w-full justify-around">
        {steps.map(({ name }, stepIdx) => (
          <li key={name} className={classNames('flex-1 flex relative justify-center')}>
            <div
              className={classNames(
                stepIdx + 1 !== steps.length ? 'flex' : 'hidden',
                'absolute top-4 inset-x-0 translate-x-1/2 items-center pointer-events-none',
              )}
              aria-hidden="true"
            >
              <div
                className={classNames(
                  'h-2 w-full',
                  step > stepIdx + 1 ? 'bg-sky-500' : 'bg-gray-200',
                )}
              />
            </div>
            <Link to={`../${stepIdx + 1}`} className="group flex flex-col items-center text-center">
              <span
                className={classNames(
                  'relative w-10 h-10 flex items-center justify-center rounded-full text-sm',
                  step < stepIdx + 1
                    ? 'bg-white text-gray-400 border-2 border-gray-300 group-hover:border-gray-400 group-hover:text-gray-500'
                    : 'bg-sky-500 text-white group-hover:bg-sky-600',
                )}
              >
                {stepIdx + 1}
              </span>
              <span
                className={classNames(
                  'mt-2 text-[0.625rem] xs:text-xs sm:text-sm',
                  step === stepIdx + 1
                    ? 'text-sky-500 group-hover:text-sky-600'
                    : 'text-gray-400 group-hover:text-gray-500',
                )}
              >
                {name}
              </span>
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
}
