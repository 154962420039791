import { CalendarCheck, CaretLeft, Tag, UserCircle } from 'phosphor-react';
import { useMemo } from 'react';
import { Link, NavLink, useMatch, useNavigate } from 'react-router-dom';
import { classNames } from '../util';
import { sites } from './BookingStart';

const navItems = [
  { name: 'Buchen', to: 'booking', icon: CalendarCheck },
  { name: 'Buchungen', to: 'mybookings', icon: Tag },
  { name: 'Profil', to: 'profile', icon: UserCircle },
] as const;

export function Header() {
  const match = useMatch('/booking/:testung/:courtCount/*');
  const navigate = useNavigate();

  const info = useMemo(() => {
    if (!match) return undefined;
    const found = sites.find(({ courtCount }) => courtCount.toString() === match.params.courtCount);
    return found;
  }, [match]);

  if (info)
    return (
      <div className="z-30 h-12 bg-white fixed inset-x-0 top-0 max-w-[414px] mx-auto border-b">
        <div className="max-w-7xl h-full mx-auto flex justify-between items-center pl-1 lg:XXpx-8 md:XXspace-x-10">
          <div
            className="flex items-center"
            onClick={() => navigate(`/booking/${match!.params.testung}`)}
          >
            <div>
              <CaretLeft className="h-5 w-5 text-gray-500" />
            </div>
            <div className="flex flex-col ml-1">
              <span className="text-sm text-gray-500 leading-none">Tennisverein Testhausen</span>
              <span className="text-slate-800 font-semibold leading-none mt-1">
                {info.sport} - {info.site}
              </span>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-end">
            {navItems.map(({ name, to, icon: Icon }) => (
              <NavLink
                key={name}
                to={to}
                className="w-10 flex flex-col text-xs justify-center items-center relative pt-1.5 pb-1"
              >
                {({ isActive }) => (
                  <>
                    <Icon
                      weight={isActive ? 'fill' : 'regular'}
                      className={classNames('w-5 h-5', isActive ? 'text-sky-500' : 'text-gray-500')}
                    />
                    {isActive && (
                      <span className="absolute w-6 -bottom-[10px] h-px bg-sky-500"></span>
                    )}
                  </>
                )}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    );

  return (
    <div className="z-30 h-12 bg-white fixed inset-x-0 top-0 max-w-[414px] mx-auto border-b">
      <div className="max-w-7xl mx-auto flex justify-between items-center pl-4 lg:XXpx-8 md:XXspace-x-10">
        <div>
          <Link to="booking" className="flex">
            <span className="sr-only"></span>
            <span className="font-extrabold text-slate-700 tracking-tighter">MATCHTIME</span>
          </Link>
        </div>
        <div className="flex-1 flex items-center justify-end">
          {navItems.map(({ name, to, icon: Icon }) => (
            <NavLink
              key={name}
              to={to}
              className="w-16 flex flex-col text-xs justify-center items-center relative pt-1.5 pb-1"
            >
              {({ isActive }) => (
                <>
                  <Icon
                    weight={isActive ? 'fill' : 'regular'}
                    className={classNames('w-5 h-5', isActive ? 'text-sky-500' : 'text-gray-500')}
                  />
                  <span
                    className={classNames('mt-0.5', isActive ? 'text-sky-500' : 'text-gray-400')}
                  >
                    {name}
                  </span>
                  {isActive && <span className="absolute w-14 -bottom-px h-px bg-sky-500"></span>}
                </>
              )}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}
