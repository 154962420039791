/* eslint-disable react/style-prop-object */
import { differenceInMinutes } from 'date-fns';
import { ArrowBendRightUp, Timer, X } from 'phosphor-react';
import React, { useCallback } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { classNames } from '../util';
import { DateInterval } from './Interval';

export interface BookingOption {
  courtId: number;
  available: boolean;
  price: number;
  ecoDiscount: number;
  ecoFriendly: boolean;
}

export function BookingSelect({
  bookingOptions,
  selectedCourt,
  selectedInterval,
  onClearSelection,
  onCourtSelectionChange,
}: {
  bookingOptions: BookingOption[];
  selectedCourt?: number;
  selectedInterval?: DateInterval;
  onClearSelection: () => void;
  onCourtSelectionChange: (courtId: number) => void;
}) {
  const navigate = useNavigate();
  const selectedOption = bookingOptions.find(({ courtId }) => courtId === selectedCourt);

  const disabled = !selectedInterval;

  const onCourtChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onCourtSelectionChange(parseInt(e.target.value));
    },
    [onCourtSelectionChange],
  );

  return (
    <div className="fixed inset-x-0 bottom-0 bg-white rounded-t-2xl shadow-2xl p-4 max-w-[414px] mx-auto">
      {selectedInterval && (
        <div className="flex items-center justify-between mb-2">
          <div className="text-lg font-semibold">
            {/* TODO time tag */}
            <FormattedMessage
              defaultMessage="{startTime,time,short} bis {endTime,time,short} Uhr"
              id="NziVSlz0"
              values={{ startTime: selectedInterval.start, endTime: selectedInterval.end }}
            />
          </div>

          <div className="flex items-center text-sm text-gray-600 font-semibold">
            <Timer weight="fill" className="h-4 w-4 mr-0.5" aria-hidden="true" />
            <FormattedMessage
              defaultMessage="{durationMin} min"
              id="laJvWfMA"
              values={{
                durationMin: differenceInMinutes(selectedInterval.end, selectedInterval.start),
              }}
            />
          </div>

          <button
            onClick={onClearSelection}
            className="bg-white rounded flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-gray-500"
          >
            <span className="sr-only">Close</span>
            <X weight="bold" className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      )}
      <div
        className={classNames(
          'text-gray-500 text-sm text-right mr-3 -mb-2 -mt-1',
          !disabled && 'hidden',
        )}
      >
        <FormattedMessage defaultMessage="Bitte Uhrzeit wählen" id="2udJWIrt" />
        <ArrowBendRightUp className="h-5 w-5 inline-block ml-1 mb-3" />
      </div>
      <div className={classNames('h-12 flex', disabled && 'hidden')}>
        <div className="h-full relative">
          <select
            className="h-full w-28 xs:w-32 block mr-2 pl-3 pr-10 py-2 shadow text-base text-white border-gray-300 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm rounded-md"
            value={selectedCourt}
            onChange={onCourtChange}
          >
            {bookingOptions.map(({ courtId, available, ecoFriendly, price, ecoDiscount }) => (
              <option value={courtId} disabled={!available}>
                <FormattedMessage
                  defaultMessage="Platz {shortName}"
                  id="GiKemvdN"
                  values={{ shortName: courtId + 1 }}
                />
                {' · '}
                {available ? (
                  <>
                    <FormattedNumber
                      value={(price - ecoDiscount) / 100}
                      style="currency"
                      currency="EUR"
                    />
                    {ecoFriendly && (
                      <>
                        {' · '}
                        <FormattedMessage defaultMessage="Nachhaltig" id="eO5BFB/1" />
                      </>
                    )}
                  </>
                ) : (
                  <FormattedMessage defaultMessage="Nicht verfügbar" id="DiIeEEjK" />
                )}
              </option>
            ))}
          </select>
          <div className="flex absolute inset-0 pl-3 items-center pointer-events-none">
            <FormattedMessage
              defaultMessage="Platz {shortName}"
              id="GiKemvdN"
              values={{ shortName: (selectedCourt || 0) + 1 }}
            />
          </div>
        </div>
        <button
          type="button"
          disabled={disabled}
          onClick={() => navigate('2')}
          className={classNames(
            'flex-1 flex items-center px-4 rounded-md text-sm leading-4 shadow-md',
            'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-sky-500',
            disabled ? 'text-gray-50 bg-gray-200' : 'text-white bg-sky-500 hover:bg-sky-600',
          )}
        >
          <span className="flex-1 text-base leading-5 font-semibold flex items-center justify-center">
            <FormattedMessage defaultMessage="Weiter" id="g6vg8nye" />
            {/* <ArrowRight weight="bold" className="hidden xs:block w-6 h-6 ml-2" /> */}
          </span>
          <span className="flex-1 flex justify-center">
            <span className="flex flex-col items-end">
              {!!selectedOption?.ecoDiscount && (
                <span className="text-[9px] leading-none line-through">
                  <FormattedNumber
                    value={selectedOption.price / 100}
                    style="currency"
                    currency="EUR"
                  />
                </span>
              )}
              <span className="flex items-center">
                {!!selectedOption?.ecoFriendly && <FaLeafSolidIcon className="w-3 h-3 mr-1" />}
                {selectedOption && (
                  <FormattedNumber
                    value={(selectedOption.price - selectedOption.ecoDiscount) / 100}
                    style="currency"
                    currency="EUR"
                  />
                )}
              </span>
            </span>
          </span>
        </button>
      </div>
      <div className="mt-2 flex justify-around">
        <button
          className="text-sky-500 text-sm p-1 focus:outline-sky-500"
          onClick={() => alert('Ausstattung buchen in Demo nicht verfügbar')}
        >
          Ausstattung buchen
        </button>
        <button
          className="text-sky-500 text-sm p-1 focus:outline-sky-500"
          onClick={() => alert('Abo / Block buchen in Demo nicht verfügbar')}
        >
          Abo / Block buchen
        </button>
      </div>
    </div>
  );
}
