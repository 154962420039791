import React, { useMemo } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import deLng from '../i18n/compiled/de.json';
import enLng from '../i18n/compiled/en.json';

type MessagesType = Record<string, string> | Record<string, MessageFormatElement[]> | undefined;

export function I18nProvider({ children }: { children: React.ReactNode }) {
  // const { locale } = useRouter();
  // const shortLocale = locale?.split('-')[0].toLowerCase() || 'de';
  const shortLocale = 'de' as string;

  const messages = useMemo(() => {
    switch (shortLocale) {
      case 'en':
        return enLng;
      case 'de':
      default:
        return deLng;
    }
  }, [shortLocale]);

  return (
    <IntlProvider locale={shortLocale} defaultLocale="de" messages={messages as MessagesType}>
      {children}
    </IntlProvider>
  );
}
