/* eslint-disable react/style-prop-object */
import { areIntervalsOverlapping, formatISO, isAfter, isBefore } from 'date-fns';
import { Check, ShoppingCart, X } from 'phosphor-react';
import { useCallback } from 'react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { classNames } from '../util';
import { DateInterval } from './Interval';

/**
 * function to make dynamic spacing pretty for 1 to 12 courts
 * returns percentage value for margin left/right
 */
function scaleStripMarginX(courtCount: number) {
  // width: `calc(100% / ${len} - ${80 / (len * len + 40)}rem)`,
  // width: `calc(100% / ${len} - ${450 / (len * len + 40)}%)`,
  // width: `min(${100 / len - 450 / (len * len + 40)}%, 1rem)`,
  const x = courtCount;
  return 225 / (x * x + 40);
}

export interface BookingInfo {
  courtId: number;
  booking: DateInterval | undefined;
  nextBooking: DateInterval | undefined;
  selectable: boolean | undefined;
  ecoFriendly: boolean;
  price: number | undefined;
  ecoDiscount: number | undefined;
}

export function BookingSlot({
  bookingInfos,
  interval,
  selectedCourt,
  selectedInterval,
  onSelectChange,
  onCourtSelectionChange,
}: {
  bookingInfos: BookingInfo[];
  interval: DateInterval;
  selectedCourt?: number;
  selectedInterval?: DateInterval;
  onSelectChange: (selected: boolean, interval: DateInterval) => void;
  onCourtSelectionChange: (courtId: number) => void;
}) {
  const selected = !!selectedInterval && areIntervalsOverlapping(interval, selectedInterval);
  const { start, end } = interval;
  const details = bookingInfos.length <= 3;
  const slotSelected = bookingInfos.map(({ selectable }) => selected && selectable);
  const slotConnectedTop = bookingInfos.map(
    ({ booking }, i) =>
      (booking && isBefore(booking.start, start)) ||
      (slotSelected[i] && isBefore(selectedInterval!.start, start)),
  );
  const slotConnectedBottom = bookingInfos.map(
    ({ booking }, i) =>
      (booking && isAfter(booking.end, end)) ||
      (slotSelected[i] && isAfter(selectedInterval!.end, end)),
  );

  const notSelectable = bookingInfos.every(({ selectable }) => !selectable);

  const minPrice = bookingInfos.reduce(
    (minPrice, { price, ecoDiscount }) => Math.min(minPrice, (price || 0) - (ecoDiscount || 0)),
    Number.MAX_SAFE_INTEGER,
  );

  const maxPrice = bookingInfos.reduce(
    (maxPrice, { price, ecoDiscount }) => Math.max(maxPrice, (price || 0) - (ecoDiscount || 0)),
    0,
  );

  const isEcoFriendly = bookingInfos.some(({ ecoFriendly }) => ecoFriendly);

  const onCourtsClick = useCallback(
    (courtId: number) => {
      // if (typeof selectedCourt !== 'undefined' && selectedCourt !== courtId && selected) {
      //   onCourtSelectionChange(courtId);
      // } else {
      //   onSelectChange(!selected, interval);
      // }
      onSelectChange(!selected, interval);
    },
    [interval, onSelectChange, selected],
  );

  return (
    <div key={start.toISOString()} className="flex py-[0.1875rem] h-14 items-stretch w-full">
      <div
        className={`w-20 h-full flex flex-shrink-0 flex-col items-center justify-center ${
          selected
            ? 'text-sky-500' // focus-within:border-sky-400
            : 'text-gray-700' // focus-within:border-gray-300
        } ${notSelectable && 'opacity-50'}`}
      >
        <time dateTime={formatISO(start)} className="text-lg leading-none font-semibold">
          <FormattedTime value={start} />
        </time>
        <span className={`text-sm leading-none mt-0.5 ${!selected && 'text-gray-400'}`}>
          <FormattedMessage defaultMessage="bis" id="om06Wbe5" />
          &nbsp;
          <time dateTime={formatISO(end)}>
            <FormattedTime value={end} />
          </time>
        </span>
      </div>

      <div className="flex-1 ml-1 min-w-0 max-w-full flex relative after:border-b after:absolute after:left-0 after:right-0 after:-bottom-0.5">
        {bookingInfos.map(({ booking, courtId, ecoFriendly, selectable }, i) => (
          <div
            onClick={selectable ? () => onCourtsClick(courtId) : undefined}
            key={courtId}
            style={{
              margin: `0 ${scaleStripMarginX(bookingInfos.length)}%`,
            }}
            className="flex-1 min-w-0 flex items-center justify-center"
          >
            <div
              style={{
                // function to make spacing pretty for 1 to 12 courts
                // width: `calc(100% / ${len} - ${80 / (len * len + 40)}rem)`,
                // width: `calc(100% / ${len} - ${450 / (len * len + 40)}%)`,
                // width: `min(${100 / len - 450 / (len * len + 40)}%, 1rem)`,
                width: `min(100%, 1rem)`,
              }}
              className={`h-10 flex-shrink-0 relative transition-all ${
                (booking && 'bg-tomato-500') ||
                (slotSelected[i] && (selectedCourt === i ? 'bg-sky-400' : 'bg-sky-500')) ||
                'bg-pear-500'
              } ${selectedInterval && !selectable && 'opacity-50'} ${
                slotConnectedTop[i] ? 'rounded-t-none' : 'rounded-t'
              } ${slotConnectedBottom[i] ? 'rounded-b-none' : 'rounded-b'}`}
            >
              <span
                className={`absolute -top-4 w-[inherit] h-4 bg-inherit transition-opacity ${
                  slotConnectedTop[i] ? 'opacity-100' : 'opacity-0'
                }`}
              ></span>
              <span className="absolute inset-0 flex flex-col justify-center items-center">
                {booking && <X weight="bold" className="max-w-[90%] max-h-[60%] text-red-600" />}
                {ecoFriendly && (
                  <FaLeafSolidIcon
                    className={`max-w-[80%] max-h-[60%] ${
                      slotSelected[i] ? 'text-white' : 'text-green-600'
                    }`}
                  />
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <label
        className={classNames(
          // details ? 'w-40' : 'w-[3.25rem] sm:w-40', TODO
          details ? 'w-40' : 'w-[3.25rem]',
          'ml-4 mr-1 h-full flex items-center bg-white rounded-lg px-3 py-1.5 border-2 shadow-sm focus-within:border-sky-300',
          selected
            ? 'border-sky-500 text-sky-500' // focus-within:border-sky-400
            : 'border-white text-gray-700', // focus-within:border-gray-300
          notSelectable && 'opacity-50',
        )}
      >
        <span
          className={classNames(
            // details ? 'flex' : 'hidden sm:flex', TODO
            details ? 'flex' : 'hidden',
            'flex-1 items-center justify-center text-sm leading-none mr-2',
            !selected && (isEcoFriendly ? 'text-green-600' : 'text-gray-400'),
          )}
        >
          {isEcoFriendly && <FaLeafSolidIcon className="w-3.5 h-3.5 mr-1 text-green-600" />}
          {minPrice !== maxPrice && <span className="mr-1">ab</span>}
          <FormattedNumber value={minPrice / 100} style="currency" currency="EUR" />
        </span>
        <span className="relative w-6 h-6">
          <input
            checked={selected}
            disabled={notSelectable}
            onChange={() => onSelectChange(!selected, interval)}
            type="checkbox"
            className="absolute inset-0 h-6 w-6 peer focus:outline-none focus:ring-0 border-none rounded-sm !bg-none !bg-transparent"
          />
          <Check weight="bold" className="h-6 w-6 hidden peer-checked:block" />
          <ShoppingCart
            weight="fill"
            className={classNames(
              'h-6 w-6 peer-checked:hidden',
              notSelectable ? 'text-gray-300' : 'text-sky-500',
            )}
          />
        </span>
      </label>
    </div>
  );
}
