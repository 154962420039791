import { addMinutes } from 'date-fns';
import React, { useContext } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { bookingContext } from './BookingContext';
import { sites } from './BookingStart';
import { isEcoFriendly } from './fake';

export function BookingSummary() {
  let { selectedInterval, selectedCourt } = useContext(bookingContext);
  const params = useParams();

  const site = sites.find(({ courtCount }) => courtCount.toString() === params.courtCount);

  selectedCourt = selectedCourt || 0;
  selectedInterval = selectedInterval || { start: new Date(), end: addMinutes(new Date(), 60) };
  const ecoFriendly = isEcoFriendly(selectedInterval, selectedCourt);

  return (
    <div className="mt-1 flex flex-col p-2 border rounded-md text-sm">
      <div className="flex items-center justify-between">
        <span>
          {site?.sport} - {site?.site}
        </span>
        <span className="font-semibold">
          <FormattedDate value={selectedInterval.start} weekday="short" />
          &nbsp;
          <FormattedDate value={selectedInterval.start} dateStyle="medium" />
        </span>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-semibold text-base">
          <FormattedMessage
            defaultMessage="Platz {shortName}"
            id="GiKemvdN"
            values={{ shortName: selectedCourt + 1 }}
          />
        </span>

        <span className="font-semibold">
          {/* TODO time tag */}
          <FormattedMessage
            defaultMessage="{startTime,time,short} bis {endTime,time,short} Uhr"
            id="NziVSlz0"
            values={{ startTime: selectedInterval.start, endTime: selectedInterval.end }}
          />
        </span>
      </div>
      {ecoFriendly && (
        <ul className="list-disc ml-8 text-gray-300">
          <li>
            <span className="inline-flex items-center text-sm text-green-600">
              <FaLeafSolidIcon className="h-3 w-3 mr-1" />
              Nachhaltig
            </span>
          </li>
        </ul>
      )}
    </div>
  );
}
