/* eslint-disable react/style-prop-object */
import { addMinutes, differenceInMinutes, formatISO, isSameMinute, set } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { classNames } from '../util';
import { BookingInfo } from './BookingSlot';
import { getPrice, isEcoFriendly, isSelectionAvailable } from './fake';
import { DateInterval } from './Interval';

export function BookingSlotDuration({
  bookingInfos,
  interval,
  selectedCourt,
  selectedInterval,
  visibleDurations,
  onSelectChange,
  onCourtSelectionChange,
}: {
  bookingInfos: BookingInfo[];
  interval: DateInterval;
  selectedCourt?: number;
  selectedInterval?: DateInterval;
  visibleDurations: number[];
  onSelectChange: (selected: boolean, interval: DateInterval) => void;
  onCourtSelectionChange: (courtId: number) => void;
}) {
  const { start } = interval;

  const durationIntervals = visibleDurations.map((durationMin) => ({
    start,
    end: addMinutes(start, durationMin),
  }));
  const durationSelected = durationIntervals.map(
    ({ start, end }) =>
      !!selectedInterval &&
      isSameMinute(selectedInterval.start, start) &&
      isSameMinute(selectedInterval.end, end),
  );

  const selectable = bookingInfos.some(({ selectable }) => selectable);

  const availableCount = bookingInfos.reduce(
    (count, { booking }) => (booking ? count - 1 : count),
    bookingInfos.length,
  );

  const maxBookingTime = useMemo(
    () =>
      bookingInfos.reduce(
        (maxBookingTime, { nextBooking }) =>
          Math.max(
            maxBookingTime,
            differenceInMinutes(
              nextBooking
                ? nextBooking.start
                : set(start, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
              start,
            ),
          ),
        0,
      ),
    [bookingInfos, start],
  );

  const durationToLastSlot = differenceInMinutes(
    set(start, { hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }),
    start,
  );

  // todo
  const byDuration = useMemo(() => {
    return durationIntervals.map((interval, i) => {
      const byCourt = bookingInfos.map((_, i) => {
        const price = getPrice(interval, i);
        return {
          courtId: i,
          available: isSelectionAvailable(interval, i),
          price: price?.price || 0,
          ecoDiscount: price?.ecoDiscount || 0,
          ecoFriendly: isEcoFriendly(interval, i),
        } as const;
      });

      const onlyAvailable = byCourt.filter(({ available }) => available);

      const durationMin = visibleDurations[i];
      const available = maxBookingTime >= durationMin && onlyAvailable.length;
      const ecoFriendly = onlyAvailable.some(({ ecoFriendly }) => ecoFriendly);

      const minPrice = onlyAvailable.reduce(
        (minPrice, { price, ecoDiscount }) => Math.min(minPrice, (price || 0) - (ecoDiscount || 0)),
        Number.MAX_SAFE_INTEGER,
      );

      const maxPrice = onlyAvailable.reduce(
        (maxPrice, { price, ecoDiscount }) => Math.max(maxPrice, (price || 0) - (ecoDiscount || 0)),
        0,
      );

      return {
        durationMin,
        available,
        minPrice,
        maxPrice,
        ecoFriendly,
      };
    });
  }, [bookingInfos, durationIntervals, maxBookingTime, visibleDurations]);

  const onDurationClick = useCallback(
    (durationIndex: number) => {
      if (selectedInterval) onSelectChange(false, selectedInterval);
      if (!durationSelected[durationIndex]) onSelectChange(true, durationIntervals[durationIndex]);
    },
    [durationIntervals, durationSelected, onSelectChange, selectedInterval],
  );

  return (
    <div
      key={start.toISOString()}
      className="flex py-[0.1875rem] h-14 items-stretch w-full relative after:border-b after:absolute after:left-0 after:right-0 after:bottom-0"
    >
      <div
        className={classNames(
          'w-20 px-1 h-full flex flex-shrink-0 flex-col items-start justify-center text-gray-700',
          !selectable && 'opacity-50',
        )}
      >
        <time dateTime={formatISO(start)} className="text-lg leading-none font-semibold">
          <FormattedTime value={start} />
        </time>
        <span className={'flex items-center text-sm leading-none text-gray-400 mt-0.5'}>
          <span
            className={classNames(
              'h-3.5 w-2 mr-1 rounded-sm',
              selectable ? 'bg-pear-500' : 'bg-tomato-500',
            )}
          />
          <FormattedMessage
            defaultMessage="{availableCount} / {totalCount} frei"
            id="jFh3LeRq"
            values={{ availableCount, totalCount: bookingInfos.length }}
          />
        </span>
      </div>

      <div className="flex-1 ml-1 min-w-0 max-w-full flex">
        {byDuration.map(({ durationMin, ecoFriendly, available, minPrice, maxPrice }, i) => (
          <div
            onClick={available ? () => onDurationClick(i) : undefined}
            key={durationMin}
            className={classNames(
              'flex-1 min-w-0 flex items-center justify-center text-sm rounded-md my-1.5 mx-2 ',
              available
                ? durationSelected[i]
                  ? 'bg-sky-500 text-white'
                  : 'bg-pear-500 text-green-800'
                : 'bg-tomato-500 text-tomato-800',
              // durationToLastSlot < durationMin && 'opacity-60',
              !available && 'opacity-60',
            )}
          >
            {available ? (
              <>
                {ecoFriendly && (
                  <FaLeafSolidIcon
                    className={classNames(
                      'w-3.5 h-3.5 mr-1',
                      durationSelected[i] ? 'text-white' : 'text-green-600',
                    )}
                  />
                )}
                {minPrice !== maxPrice && <span className="mr-1">ab</span>}
                <FormattedNumber value={minPrice / 100} style="currency" currency="EUR" />
              </>
            ) : (
              <span className="flex flex-col items-center justify-center">
                {/* <span className={classNames('leading-none', selectable && 'text-xs')}> */}
                <span className={classNames('leading-none')}>
                  {durationToLastSlot < durationMin ? (
                    <FormattedMessage defaultMessage="Nicht verfügbar" id="DiIeEEjK" />
                  ) : (
                    <FormattedMessage defaultMessage="Belegt" id="f1WHemo7" />
                  )}
                </span>
                {/* {selectable && (
                  <span className="leading-none">
                    <FormattedMessage
                      defaultMessage="max. {maxBookingTimeMinutes} min"
                      id="S5fS2G5R"
                      values={{ maxBookingTimeMinutes: maxBookingTime }}
                    />
                  </span>
                )} */}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
