/* eslint-disable react/style-prop-object */
import { addMinutes } from 'date-fns';
import { Bank, Coins, CreditCard, Wallet } from 'phosphor-react';
import React, { useContext, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { FaLeafSolidIcon } from '../assets/FaLeafSolidIcon';
import { classNames } from '../util';
import { bookingContext } from './BookingContext';
import { BookingSummary } from './BookingSummary';
import { getPrice } from './fake';

const paymentMethods = [
  { name: 'PayPal', icon: Wallet },
  { name: 'Kreditkarte', icon: CreditCard },
  { name: 'Lastschrift', icon: Bank },
] as const;

export function BookingPayment() {
  let { selectedInterval, selectedCourt } = useContext(bookingContext);
  const [paymentMethod, setPaymentMethod] = useState('PayPal');

  selectedCourt = selectedCourt || 0;
  selectedInterval = selectedInterval || { start: new Date(), end: addMinutes(new Date(), 60) };
  const price = getPrice(selectedInterval, selectedCourt);

  return (
    <div className="bg-white">
      <h2 className="text-2xl font-semibold text-gray-900">Buchung</h2>
      <div className="mt-1">
        <BookingSummary />
      </div>

      <h2 className="mt-4 text-2xl font-semibold text-gray-900">Zahlung</h2>
      <div className="mt-2 flex items-end text-gray-900">
        <Coins weight="light" className="h-7 w-7 mr-3" />
        <span
          className={classNames(
            'text-gray-400 mr-1.5',
            price.ecoDiscount ? 'line-through' : 'hidden',
          )}
        >
          <FormattedNumber value={price.price / 100} style="currency" currency="EUR" />
        </span>
        <span className="text-lg font-semibold">
          <FormattedNumber
            value={(price.price - price.ecoDiscount) / 100}
            style="currency"
            currency="EUR"
          />
        </span>
      </div>
      {!!price.ecoDiscount && (
        <div className="mt-2 flex items-center text-sm text-green-600">
          <FaLeafSolidIcon className="h-4 w-7 flex-shrink-0 mr-3" />
          <span className="">
            <FormattedNumber
              value={price.ecoDiscount / 100}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="EUR"
            />{' '}
            {/* Rabatt durch Nachhaltigkeit. Durch diese Buchung wird Energie gespart. Danke! */}
            Rabatt durch Nachhaltigkeit.
          </span>
        </div>
      )}

      <fieldset className="mt-4">
        <div className="space-y-2">
          {paymentMethods.map(({ name, icon: Icon }, i) => (
            <label
              className={classNames(
                'flex items-center px-3 py-2 text-base border rounded-md',
                paymentMethod === name ? 'border-sky-500 text-sky-500' : 'text-gray-700',
              )}
            >
              <input
                name="payment-method"
                type="radio"
                onChange={(e) => e.target.checked && setPaymentMethod(name)}
                checked={paymentMethod === name}
                className="focus:ring-sky-500 h-5 w-5 text-sky-500 border-gray-300"
              />
              <span className="ml-3 flex items-center">
                <Icon className="h-4 w-4 mr-2" />
                {name}
              </span>
            </label>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
